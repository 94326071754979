<template>
  <div>
    <b-modal
      size="lg"
      scrollable
      v-model="modalShow"
      :title="this.$i18n.t('admin.role.formRole')"
      no-close-on-backdrop
      hide-header-close
    >
      <b-row>
        <b-col cols="4">
          <label class="mt-3 font-weight-bold"> {{ $t('admin.role.role') }} * </label>
          <b-form-select v-model="form.rol" :options="itemsRol" size="sm" class="select mt-0" :disabled="isEdit">
            <template #first>
              <b-form-select-option :value="{ name: '' }" disabled
                >-- {{ $t('admin.role.selectRole') }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="!form.rol.name.toLowerCase().includes('super admin')">
        <b-col cols="4">
          <label class="mt-3 font-weight-bold">{{ $t('admin.role.country') }} *</label>
          <b-form-select v-model="form.pais" :options="optionsCountry" size="sm" class="mt-0 select" :disabled="isEdit">
            <template #first>
              <b-form-select-option value="N/A" disabled
                >-- {{ $t('admin.role.selectCountry') }} --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="!form.rol.name.toLowerCase().includes('super admin')">
        <b-col cols="6">
          <label class="mt-3 font-weight-bold">{{ $t('admin.role.branch') }} *</label>
          <multi-select
            :options="optionsSucursal"
            :selected-options="form.sucursal"
            :placeholder="this.$i18n.t('admin.role.selectBranch')"
            @select="onSelect"
          >
          </multi-select>
        </b-col>
      </b-row>
      <div class="accordion mt-5" role="tablist">
        <!-- ADMIN SECTION -->
        <b-card no-body class="mb-1" v-if="form.rol.name.toLowerCase().includes('admin')">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-admin variant="info"
              ><b>{{ $t('admin.role.administrator') }}</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-admin" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-row>
                <b-col cols="12">
                  <b-button
                    :class="formValidation.administrador.ventana ? null : 'collapsed'"
                    :aria-expanded="formValidation.administrador.ventana ? 'true' : 'false'"
                    aria-controls="collapse-4"
                    variant="light"
                    @click="formValidation.administrador.ventana = !formValidation.administrador.ventana"
                  >
                    <b-icon
                      :icon="formValidation.administrador.ventana ? 'check2-square' : 'square'"
                      variant="success"
                      aria-hidden="true"
                    ></b-icon>
                    <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
                  </b-button>
                  <b-collapse id="collapse-4" v-model="formValidation.administrador.ventana" class="mt-2">
                    <b-card>
                      <b-row>
                        <b-col cols="6" class="mt-3" v-for="(item, idx) in adminSection" :key="`admin-${idx}`">
                          <b-button
                            :class="formValidation.administrador.pestana[item.key].permiso ? null : 'collapsed'"
                            :aria-expanded="formValidation.administrador.pestana[item.key].permiso ? 'true' : 'false'"
                            aria-controls="collapse-4"
                            variant="light"
                            @click="
                              formValidation.administrador.pestana[item.key].permiso = !formValidation.administrador
                                .pestana[item.key].permiso
                            "
                          >
                            <b-icon
                              :icon="
                                formValidation.administrador.pestana[item.key].permiso ? 'check2-square' : 'square'
                              "
                              variant="success"
                              aria-hidden="true"
                            ></b-icon>
                            <b class="ml-3">{{ item.name }}</b>
                          </b-button>
                          <b-collapse
                            id="collapse-4"
                            v-model="formValidation.administrador.pestana[item.key].permiso"
                            class="mt-2"
                          >
                            <b-card>
                              <div v-for="(el, idx) in item.actions" :key="`admin-actions${idx}`">
                                <div
                                  variant="light"
                                  class="custom-actions"
                                  @click="
                                    formValidation.administrador.pestana[item.key].acciones[el.key] = !formValidation
                                      .administrador.pestana[item.key].acciones[el.key]
                                  "
                                >
                                  <b-icon
                                    :icon="
                                      formValidation.administrador.pestana[item.key].acciones[el.key]
                                        ? 'check2-square'
                                        : 'square'
                                    "
                                    variant="success"
                                    aria-hidden="true"
                                  ></b-icon>
                                  <b class="ml-3">{{ el.name }}</b>
                                </div>
                              </div>
                            </b-card>
                          </b-collapse>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-collapse>
                </b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- SSPTA SECTION -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-sspta variant="info"
              ><b>{{ $t('admin.role.sspta') }} </b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-sspta" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-row>
                <b-col cols="12">
                  <b-button
                    :class="formValidation.sspta.ventana ? null : 'collapsed'"
                    :aria-expanded="formValidation.sspta.ventana ? 'true' : 'false'"
                    aria-controls="collapse-1"
                    variant="light"
                    @click="formValidation.sspta.ventana = !formValidation.sspta.ventana"
                  >
                    <b-icon
                      :icon="formValidation.sspta.ventana ? 'check2-square' : 'square'"
                      variant="success"
                      aria-hidden="true"
                    ></b-icon>
                    <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
                  </b-button>
                  <b-collapse id="collapse-1" v-model="formValidation.sspta.ventana" class="mt-2">
                    <b-card v-if="configurationRol.id !== '615f355da4a7246cd5a9ac3d'">
                      <!-- Diferente a super admin -->
                      <b-row>
                        <b-col cols="6" class="mt-3" v-for="(item, idx) in optionsSSPTA" :key="`sspta-${idx}`">
                          <div
                            class="custom-actions"
                            @click="
                              $nextTick(() => {
                                formValidation.sspta.pestana[item.key] = !formValidation.sspta.pestana[item.key];
                              })
                            "
                          >
                            <b-icon
                              :icon="formValidation.sspta.pestana[item.key] ? 'check2-square' : 'square'"
                              variant="success"
                              aria-hidden="true"
                            ></b-icon>
                            <b class="ml-3">{{ item.name }}</b>
                          </div>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-collapse>
                </b-col>
                <b-col cols="4"></b-col>
                <b-col cols="4"></b-col>
              </b-row>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- HISTORY SECTION -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-3 variant="info"
              ><b>{{ $t('admin.role.history') }}</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-button
                :class="formValidation.history.ventana ? null : 'collapsed'"
                :aria-expanded="formValidation.history.ventana ? 'true' : 'false'"
                aria-controls="collapse-1"
                variant="light"
                @click="formValidation.history.ventana = !formValidation.history.ventana"
              >
                <b-icon
                  :icon="formValidation.history.ventana ? 'check2-square' : 'square'"
                  variant="success"
                  aria-hidden="true"
                ></b-icon>
                <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- Medicinas SECTION -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-4 variant="info"
              ><b>{{ $t('admin.role.medicines') }}</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-button
                :class="formValidation.medicina.ventana ? null : 'collapsed'"
                :aria-expanded="formValidation.medicina.ventana ? 'true' : 'false'"
                aria-controls="collapse-1"
                variant="light"
                @click="formValidation.medicina.ventana = !formValidation.medicina.ventana"
              >
                <b-icon
                  :icon="formValidation.medicina.ventana ? 'check2-square' : 'square'"
                  variant="success"
                  aria-hidden="true"
                ></b-icon>
                <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Cycles SECTION -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-5 variant="info"
              ><b>{{ $t('admin.role.cycles') }}</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-button
                :class="formValidation.cycles.ventana ? null : 'collapsed'"
                :aria-expanded="formValidation.cycles.ventana ? 'true' : 'false'"
                aria-controls="collapse-1"
                variant="light"
                @click="formValidation.cycles.ventana = !formValidation.cycles.ventana"
              >
                <b-icon
                  :icon="formValidation.cycles.ventana ? 'check2-square' : 'square'"
                  variant="success"
                  aria-hidden="true"
                ></b-icon>
                <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Recipe SECTION -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-6 variant="info"
              ><b>{{ $t('admin.role.recipe') }}</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-button
                :class="formValidation.recipe.ventana ? null : 'collapsed'"
                :aria-expanded="formValidation.recipe.ventana ? 'true' : 'false'"
                aria-controls="collapse-1"
                variant="light"
                @click="formValidation.recipe.ventana = !formValidation.recipe.ventana"
              >
                <b-icon
                  :icon="formValidation.recipe.ventana ? 'check2-square' : 'square'"
                  variant="success"
                  aria-hidden="true"
                ></b-icon>
                <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>

        <!-- Onboardin medical -->
        <b-card no-body class="mb-1">
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle.accordion-7 variant="info"
              ><b>{{ $t('formMedical.titulo') }}</b></b-button
            >
          </b-card-header>
          <b-collapse id="accordion-7" accordion="my-accordion" role="tabpanel">
            <b-card-body v-if="formValidation">
              <b-button
                :class="formValidation.onboardMedico && formValidation.onboardMedico.ventana ? null : 'collapsed'"
                :aria-expanded="formValidation.onboardMedico && formValidation.onboardMedico.ventana ? 'true' : 'false'"
                aria-controls="collapse-1"
                variant="light"
                @click="changeStatusOnboarding()"
              >
                <b-icon
                  :icon="
                    formValidation.onboardMedico && formValidation.onboardMedico.ventana ? 'check2-square' : 'square'
                  "
                  variant="success"
                  aria-hidden="true"
                ></b-icon>
                <b class="ml-3">{{ $t('admin.role.accessWindow') }}</b>
              </b-button>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <template #modal-footer>
        <b-row>
          <b-col class="justify-content-end buttons-dropdown">
            <b-button class="btn btn-blue-dark-outline mr-3" @click="$emit('closeModal')">
              {{ $t('admin.users.cancel') }}
            </b-button>

            <b-button class="btn btn-blue-dark ml-0" @click="handleConfig" :disabled="isLoadingDone">
              <span v-if="!isLoadingDone">
                <span v-if="isEdit">Editar</span>
                <span v-else>Crear</span>
                <b-icon icon="arrow-right" class="icon ml-2" aria-hidden="true"></b-icon>
              </span>
              <span v-else
                ><b-spinner small class="align-middle"></b-spinner>
                {{ $t('admin.medicines.loading') }}
              </span>
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { API_HUBSPOT, API_ROOT } from '../../../Constants/Api';

import { initialAdmin } from './initialDataFormRol';
import { AlertForm, ICON } from '../../../helpers/Alert';
import { MultiSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';
import { ApiRequest } from '../../../helpers/ApiRequest';
export default {
  name: 'FormRolAdmin',
  components: { MultiSelect },
  props: ['modalShow', 'validation', 'configurationRol', 'isEdit', 'itemsRol', 'getConfigRoles', 'allConfigRol'],
  data: () => ({
    rolName: '',
    isLoadingDone: false,
    isLoadingRolesList: false,
    isLoadingConfigRol: false,
    isLoadingSaveConfig: false,
    filterRol: null,
    filterOn: [],
    form: { rol: { name: '' }, sucursal: [] },
    optionsCountry: [
      { text: 'USA', value: 'US' },
      { text: 'Mexico', value: 'MX' },
    ],
    optionsSucursal: [],

    //checkbox configuration
    selectedSSPTA: [],
    searchText: '', // If value is falsy, reset searchText & searchItem

    itemsCategory: [],
    formValidation: null,
  }),
  async mounted() {
    this.form = this.configurationRol;
  },

  computed: {
    pais() {
      return this.form.pais;
    },
    optionsSSPTA() {
      let options = [
        { name: this.$i18n.t('admin.role.programs'), key: 'programs' },
        { name: this.$i18n.t('admin.role.techniques'), key: 'technics' },
        { name: this.$i18n.t('admin.role.labs'), key: 'labs' },
        { name: this.$i18n.t('admin.role.surgeries'), key: 'surgeries' },
        { name: this.$i18n.t('admin.role.treatment'), key: 'treatment' },
        { name: this.$i18n.t('admin.role.individualMedicina'), key: 'medicinesIndividual' },
        { name: this.$i18n.t('admin.role.packageMedicine'), key: 'medicinesPackage' },
      ];
      for (const item of this.itemsCategory) {
        options.push({ name: item, key: item });
      }

      return options;
    },
    adminSection() {
      return initialAdmin(this);
    },
  },

  watch: {
    async pais() {
      if (this.pais !== 'N/A') {
        if (!this.isEdit) this.form.sucursal = [];
        await this.getSucursales(this.pais);
        await this.getProductsXCountry(this.pais);
      }
    },
    modalShow() {
      if (this.modalShow) {
        this.form = this.configurationRol;
        this.form.sucursal = this.configurationRol.sucursal.map((el) => ({ text: el, value: el }));
        this.formValidation = JSON.parse(JSON.stringify(this.validation));
      } else {
        this.form = { pais: 'N/A', rol: { name: '' }, sucursal: [] };
      }
    },
    optionsSSPTA() {
      for (const item of this.itemsCategory) {
        if (!this.validation.sspta.pestana[item]) {
          this.validation.sspta.pestana[item] = false;
        }
      }
      this.formValidation = JSON.parse(JSON.stringify(this.validation));
    },
  },

  methods: {
    changeStatusOnboarding() {
      if (this.formValidation.onboardMedico) {
        this.formValidation.onboardMedico.ventana = !this.formValidation.onboardMedico.ventana;
      } else {
        var elementOnb = { onboardMedico: { ventana: true } };
        this.formValidation = { ...JSON.parse(JSON.stringify(this.formValidation)), ...elementOnb };
      }
    },
    async handleConfig() {
      if (!this.form.rol._id)
        // valida si tiene un rol
        return AlertForm(
          this.$i18n.t('permissions.emptyFields'),
          this.$i18n.t('permissions.notSelectRol'),
          ICON.WARNING,
          'center',
          null
        );
      if (!this.form.rol.name.toLowerCase().includes('super admin')) {
        if (this.form.pais === 'N/A')
          return AlertForm(
            this.$i18n.t('permissions.emptyFields'),
            this.$i18n.t('permissions.notSelectCountry'),
            ICON.WARNING,
            'center',
            null
          );
        if (this.form.sucursal.length === 0)
          return AlertForm(
            this.$i18n.t('permissions.emptyFields'),
            this.$i18n.t('permissions.notSelectBranch'),
            ICON.WARNING,
            'center',
            null
          );
      }
      if (this.isEdit) {
        try {
          this.isLoadingDone = true;
          let filterDataConfig = this.allConfigRol.find((el) => el._id === this.form.rol._id);
          filterDataConfig = filterDataConfig.configuracion;
          let filterInfo = filterDataConfig.filter((el) => el.id !== this.form.idConfig);

          for (const sucursal of this.form.sucursal) {
            let duplicateInfo = filterInfo.find(
              (el) => el.pais === this.form.pais && el.sucursal.includes(sucursal.value)
            );
            if (duplicateInfo)
              return AlertForm(
                this.$i18n.t('permissions.notCreate'),
                `${this.$i18n.t('permissions.existConfiguration')}: ${sucursal.value}`,
                ICON.WARNING,
                'center'
              );
          }
          await ApiRequest.post(`${API_ROOT}roles/configRol`, {
            id: this.form.rol._id,
            configuracion: [
              ...filterInfo,
              {
                pais: this.form.pais,
                sucursal: this.form.sucursal.map((el) => el.value),
                vistas: this.formValidation,
              },
            ],
          });
          AlertForm(this.$i18n.t('permissions.msgConfigRol'), '', ICON.SUCCESS);
          this.$emit('closeModal');
          await this.getConfigRoles();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t('permissions.errorConfigRol'), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
        }
      } else {
        try {
          this.isLoadingDone = true;
          let filterDataConfig = this.allConfigRol.find((el) => el._id === this.form.rol._id);
          filterDataConfig = filterDataConfig ? filterDataConfig.configuracion : [];
          for (const sucursal of this.form.sucursal) {
            let duplicateInfo = filterDataConfig.find(
              (el) => el.pais === this.form.pais && el.sucursal.includes(sucursal.value)
            );
            if (duplicateInfo)
              return AlertForm(
                this.$i18n.t('permissions.notCreate'),
                `${this.$i18n.t('permissions.existConfiguration')}: ${sucursal.value}`,
                ICON.WARNING,
                'center'
              );
          }

          await ApiRequest.post(`${API_ROOT}roles/configRol`, {
            id: this.form.rol._id,
            configuracion: [
              ...filterDataConfig,
              {
                id: Date.parse(new Date()),
                pais: this.form.pais,
                sucursal: this.form.sucursal.map((el) => el.value),
                vistas: this.formValidation,
              },
            ],
          });
          AlertForm(this.$i18n.t('permissions.createConfigRol'), '', ICON.SUCCESS, 'center');
          this.$emit('closeModal');
          await this.getConfigRoles();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t('permissions.errorCreateConfigRol'), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
        }
      }
    },
    async getSucursales(pais) {
      try {
        if (pais === 'US') {
          let us = await ApiRequest.get(`${API_HUBSPOT}sucursalesUS`);
          this.optionsSucursal = us.sucursales.map((el) => ({
            text: el,
            value: el,
          }));
        } else {
          let mx = await ApiRequest.get(`${API_HUBSPOT}sucursalesMX`);
          this.optionsSucursal = mx.sucursales.map((el) => ({
            text: el,
            value: el,
          }));
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorBranch'), ICON.ERROR);
      }
    },
    async getProductsXCountry(pais) {
      try {
        const data = await ApiRequest.post(`${API_ROOT}cards/productosCategoriaPais`, {
          localidad: pais === 'US' ? 2 : 1,
        });
        let allProducts = data.categorias_productos;
        let deleteCategories = [
          'BEC',
          'COVERAGE',
          'PAQUETE MEDICAMENTO',
          'CIRUGIA',
          'LAB.GRAL',
          'FUNGIBLES - MEDICAMENTOS',
          'BEC INTERNACIONAL',
          'LABS',
          'SURGERIES',
          'TECHNICS',
          'TREATMENT'
        ];
        for (const category of deleteCategories) {
          delete allProducts[category];
        }
        this.itemsCategory = Object.keys(allProducts);
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t('admin.top.errorProducts'), ICON.ERROR);
      }
    },
    onSelect(items) {
      this.form.sucursal = items;
    },
  },
};
</script>

<style scoped>
@import '../../../assets/css/global.css';

.custom-actions {
  cursor: pointer;
}
.rol {
  min-height: 100vh;
}

.rol .create-rol {
  max-width: 400px;
  margin: 3rem 0 2rem 2rem;
}
.rol .create-rol input {
  flex: 1;
}

/* delete */
.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.rol .configuracion {
  margin-top: 4rem;
}

.rol /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

.modal-config label {
  font-weight: bold;
  margin-top: 1rem;
}
</style>
