<template>
  <div class="rol">
    <h3>{{ $t("admin.role.subtitle") }}</h3>
    <div class="create-rol" id="form-role">
      <label
        ><b
          >{{ $t("admin.role.name") }} -
          <span v-if="isEditRol">{{ $t("admin.role.editMode") }}</span>
          <span v-else> {{ $t("admin.role.createMode") }}</span></b
        >
      </label>
      <b-row>
        <b-form-input
          v-model="rolName"
          :placeholder="this.$i18n.t('admin.role.addRole')"
          :state="validationNameRol"
        ></b-form-input>
        <b-button
          class="btn btn-blue-dark ml-3"
          :disabled="isLoadingDone || !validationNameRol"
          v-if="validationSaveRol"
          @click="saveRol"
        >
          <span v-if="isLoadingDone"
            ><b-spinner small class="align-middle"></b-spinner> Loading...</span
          >
          <span v-else>{{ $t("admin.medicines.done") }}</span>
        </b-button>
        <b-button
          class="btn btn-blue-dark ml-3"
          :disabled="isLoadingDone"
          v-if="isEditRol && !isLoadingDone"
          @click="createMode"
        >
          Modo Crear
        </b-button>
      </b-row>
    </div>
    <small>{{ $t("admin.role.msgRole") }}</small>
    <template
      v-if="configRol.administrador.pestana.adminRoles.acciones.listarRol"
    >
      <b-table
        :items="itemsRol"
        :fields="fields"
        class="mt-5"
        thead-tr-class="headerClass"
        :busy="isLoadingRolesList"
      >
        <template #cell(text)="data">
          <b>{{ data.value }}</b>
        </template>

        <template #cell(actions)="row">
          <a href="#form-role">
            <b-button
            size="sm"
            class="mr-2 bg-success"
            @click="updateRol(row.item)"
            v-if="configRol.administrador.pestana.adminRoles.acciones.editarRol"
          >
            {{ $t("admin.role.edit") }}
          </b-button>
          </a>
          
          <b-button
            size="sm"
            class="mr-2 bg-danger"
            @click="handleDeleteRol(row.item.value._id)"
            v-if="
              configRol.administrador.pestana.adminRoles.acciones.eliminarRol
            "
          >
            {{ $t("admin.role.delete") }}
          </b-button>
        </template>
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
      </b-table>
    </template>
    <template v-else>
      <b-alert show dismissible>
        <p>
          {{ $t("admin.role.msgPermissions") }}
        </p>
      </b-alert>
    </template>
    <hr />
    <!-- SECTION CONFIGURACION -->

    <div role="group" class="configuracion">
      <b-row class="mb-4 align-items-center text-align-end">
        <b-col cols="5"></b-col>
        <b-col>
          <b-input-group size="md">
            <b-form-input
              id="filter-input"
              v-model="filterRol"
              type="search"
              class="custom-input"
              :placeholder="this.$i18n.t('admin.role.search')"
            >
            </b-form-input>
          </b-input-group>
          <b-icon
            icon="search"
            aria-hidden="true"
            flip-h
            class="input__search__icon"
          ></b-icon>
        </b-col>
        <b-col>
          <b-button
            variant="outline-primary"
            class="button btn-blue-dark ml-0"
            @click="newConfiguration"
            v-if="
              configRol.administrador.pestana.adminRoles.acciones.crearConfigRol
            "
          >
            {{ $t("admin.role.createConf") }}
          </b-button>
        </b-col>
      </b-row>
      <!-- TABLE STATUS ACTIVE-->
      <template
        v-if="
          configRol.administrador.pestana.adminRoles.acciones.listarConfigRol
        "
      >
        <b-table
          :items="itemsDataConfig"
          :fields="fieldsRol"
          :filter="filterRol"
          :filter-included-fields="filterOn"
          stacked="lg"
          show-empty
          hover
          table-variant="light"
          class="tableFirst"
          sort-icon-left
          borderless
          thead-tr-class="table-role"
          tbody-tr-class="table-role"
          :busy="isLoadingConfigRol"
        >
          <template #cell(rol)="row">
            <b>{{ row.value }}</b>
          </template>
          <template #cell(pais)="row">
            {{
              row.value === "US" ? "USA" : row.value === "MX" ? "Mexico" : "N/A"
            }}
          </template>
          <template #cell(sucursal)="row">
            {{
              row.value.length ? row.value.join(", ") : "N/A"
            }}
          </template>
          <template #cell(actions)="row">
            <b-button
              size="sm"
              class="mr-2 bg-success"
              @click="updateConfiguration(row.item)"
              v-if="
                configRol.administrador.pestana.adminRoles.acciones
                  .editarConfigRol
              "
            >
              {{ $t("admin.role.edit") }}
            </b-button>
            <b-button
              size="sm"
              class="mr-2"
              @click="cloneConfiguration(row.item)"
              v-if="
                configRol.administrador.pestana.adminRoles.acciones.clonarRol
              "
            >
              {{ $t("admin.role.clone") }}
            </b-button>
            <b-button
              size="sm"
              class="mr-2 bg-danger"
              @click="handleDeleteConfig(row.item)"
              v-if="
                configRol.administrador.pestana.adminRoles.acciones
                  .eliminarConfigRol
              "
            >
              {{ $t("admin.role.delete") }}
            </b-button>
          </template>
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </template>
      <template v-else>
        <b-alert show dismissible>
          <p>
            {{ $t("admin.role.msgPermissionList") }}
          </p>
        </b-alert>
      </template>
    </div>
    <!-- MODAL CONFIGURACION -->

    <ModalForm
      :modalShow="modalShow"
      @closeModal="closeModal"
      :validation="validation"
      :configurationRol="configurationRol"
      :isEdit="isEditConfig"
      :itemsRol="itemsRol"
      :getConfigRoles="getConfigRoles"
      :allConfigRol="allConfigRol"
    />
  </div>
</template>

<script>
import ModalForm from "./Rol/ModalForm.vue";
import { initialForm } from "./Rol/initialDataFormRol";
import { AlertConfirm, AlertForm, ICON } from "../../helpers/Alert";
import { API_ROOT } from "../../Constants/Api";
import { ApiRequest } from '../../helpers/ApiRequest';

export default {
  name: "RolAdmin",
  components: { ModalForm },

  data: () => ({
    rolName: "",
    isLoadingDone: false,
    itemsRol: [],
    isLoadingRolesList: false,
    isLoadingConfigRol: false,
    isLoadingSaveConfig: false,
    itemsDataConfig: [],
    filterRol: null,
    filterOn: [],
    modalShow: false,
    //checkbox configuration
    selectedSSPTA: [],
    visible: false,
    validation: JSON.parse(JSON.stringify(initialForm)),
    configurationRol: {
      pais: "N/A",
      id: "",
      idConfig: null, 
      sucursal: "N/A",
      rol: {
        name: "",
      },
    },
    isEditConfig: false,
    isEditRol: false,
    idRol: null,
    configRol: {},
    allConfigRol: [],
  }),
  created() {
    this.configRol = this.$store.state.configRol;
    if (this.configRol.administrador?.pestana.adminRoles.permiso === false) {
      this.$router.push({ name: "Welcome" });
      AlertForm(`${this.$i18n.t("permissions.unauthorized")} ${this.$i18n.t("admin.role.title")}` , this.$i18n.t("permissions.validateAdmin"), ICON.WARNING, "center", null);
    }
  },
  async mounted() {
    await this.getRoles();
    await this.getConfigRoles();
  },
  computed: {
    validationSaveRol() {
      if (
        this.configRol.administrador.pestana.adminRoles.acciones.crearRol &&
        !this.isEditRol
      ) {
        return true;
      } else if (
        this.configRol.administrador.pestana.adminRoles.acciones.editarRol &&
        this.isEditRol
      ) {
        return true;
      }
      return false;
    },
    validationNameRol() {
      return this.rolName.length > 2;
    },
    hide() {
      return this.$i18n.t("admin.medicines.hide");
    },
    fields() {
      return [
        {
          key: "text",
          label: this.$i18n.t("admin.role.name"),
        },
        {
          key: "isAdmin",
          label: "Rol Admin"
        },
        {
          key: "actions",
          label: this.$i18n.t("admin.role.actions"),
        },
      ];
    },
    fieldsRol() {
      return [
        {
          key: "rol",
          label: this.$i18n.t("admin.medicines.name"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "pais",
          label: this.$i18n.t("admin.role.country"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "sucursal",
          label: this.$i18n.t("admin.role.branch"),
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "actions",
          label: this.$i18n.t("admin.role.actions"),
          sortable: true,
          sortDirection: "desc",
        },
      ];
    },
  },
  methods: {
    async getRoles() {
      try {
        this.rolName = "";
        this.isLoadingRolesList = true;
        let data = await ApiRequest.get(`${API_ROOT}roles`);
        this.itemsRol = data.lista.map((el) => {
          return {
            text: el.name,
            isAdmin: el.name.toLowerCase().includes("admin") ? "Yes": "No",
            value: el,
          };
        });
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorRole"), ICON.ERROR); 
      } finally {
        this.isLoadingRolesList = false;
      }
    },
    async getConfigRoles() {
      try {
        this.isLoadingConfigRol = true;
        let data = await ApiRequest.get(`${API_ROOT}roles/obtenerConfigsRol`);
        this.itemsDataConfig = [];
        this.allConfigRol = data.result;
        for (const configRol of data.result) {
          for (const item of configRol.configuracion) {
            this.itemsDataConfig.push({
              id: configRol._id,
              rol: configRol.name,
              pais: item.pais,
              sucursal: item.sucursal,
              vistas: item.vistas,
              idConfig: item.id
            });
          }
        }
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorConfigRole"), ICON.ERROR);
      } finally {
        this.isLoadingConfigRol = false;
      }
    },
    async saveRol() {
      if (this.isEditRol) {
        try {
          this.isLoadingDone = true;
          await ApiRequest.put(`${API_ROOT}roles`, {
            nombre_rol: this.rolName,
            id: this.idRol,
          });
          AlertForm( this.$i18n.t("permissions.updateRol"),"", ICON.SUCCESS);
          await this.getRoles();
          await this.getConfigRoles();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorUpdateRol"), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
          this.isEditRol = false;
        }
      } else {
        try {
          this.isLoadingDone = true;
          await ApiRequest.post(`${API_ROOT}roles`, {
            nombre_rol: this.rolName,
          });
         
          AlertForm(this.$i18n.t("permissions.createRol"), "", ICON.SUCCESS);
          await this.getRoles();
        } catch (err) {
          AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorCreateRol"), ICON.ERROR);
        } finally {
          this.isLoadingDone = false;
        }
      }
    },
    newConfiguration() {
      this.modalShow = true;
      this.configurationRol.pais = "N/A";
      this.configurationRol.rol = { name: "" };
      this.configurationRol.sucursal = [];
      this.configurationRol.id = 0;
      this.isEditConfig = false;
      this.validation = JSON.parse(JSON.stringify(initialForm));
    },
    updateConfiguration(data) {
      
      this.modalShow = true;
      this.configurationRol.pais = data.pais;
      this.configurationRol.rol = { name: data.rol, _id: data.id };
      this.configurationRol.sucursal = data.sucursal;
      this.configurationRol.id = data.id;
      this.configurationRol.idConfig = data.idConfig;
      this.isEditConfig = true;
      
      /* Validar objeto si se crea una nueva perstaña en Admin */
      for (const el in initialForm.administrador.pestana) {
        if(!data.vistas.administrador.pestana[el]){
          data.vistas.administrador.pestana =  {...data.vistas.administrador.pestana, [el]: JSON.parse(JSON.stringify(initialForm.administrador.pestana[el]))};
        }
      }
      this.validation = JSON.parse(JSON.stringify(data.vistas));
    },
    cloneConfiguration(data) {

      this.modalShow = true;
      this.configurationRol.pais = "N/A";
      this.configurationRol.rol = { name: "" };
      this.configurationRol.sucursal = [];
      this.configurationRol.id = 0;
      this.isEditConfig = false;
      /* Validar objeto si se crea una nueva perstaña en Admin */
      for (const el in initialForm.administrador.pestana) {
        if(!data.vistas.administrador.pestana[el]){
          data.vistas.administrador.pestana[el] = JSON.parse(JSON.stringify(initialForm.administrador.pestana[el]));
        }
      }
      this.validation = JSON.parse(JSON.stringify(data.vistas));
    },
    handleDeleteConfig(item) {
      AlertConfirm(
        this.$i18n.t("permissions.deleteRolConfig"),
        this.$i18n.t("permissions.msgSure"),
        ICON.WARNING,
        () => this.deleteItem(item)
      );
    },
    async deleteItem(item) {
      let filterDataConfig = this.allConfigRol.find((el) => el._id === item.id);
      filterDataConfig = filterDataConfig.configuracion;
      let filterInfo = filterDataConfig.filter(
        (el) => !(el.pais === item.pais && el.sucursal === item.sucursal)
      );
      try {
        await ApiRequest.post(`${API_ROOT}roles/configRol`, {
          id: item.id,
          configuracion: [...filterInfo],
        });
        
        AlertForm(this.$i18n.t("permissions.msgConfigRole"),"", ICON.SUCCESS);
        await this.getConfigRoles();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorDeleteRolConfig"), ICON.ERROR);
      }
    },
    handleDeleteRol(id) {
      AlertConfirm(
        this.$i18n.t("permissions.deleteRol"),
        this.$i18n.t("permissions.msgSure"),
        ICON.WARNING,
        () => this.deleteRol(id)
      );
    },
    async deleteRol(id) {
      try {
        await ApiRequest.delete(`${API_ROOT}roles`, {id});
        AlertForm(this.$i18n.t("permissions.msgRole"), "", ICON.SUCCESS);
        await this.getRoles();
        await this.getConfigRoles();
      } catch (err) {
        AlertForm(`Error Code: ${err.status}`, this.$i18n.t("permissions.errorDeleteRol"), ICON.ERROR);
      }
    },
    updateRol(data) {
      this.isEditRol = true;
      this.rolName = data.value.name;
      this.idRol = data.value._id;
    },
    createMode() {
      this.isEditRol = false;
      this.rolName = "";
    },
    closeModal() {
      this.modalShow = false;
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/global.css";

.rol {
  min-height: 100vh;
}

.rol .create-rol {
  width: 500px;
  margin: 3rem 0 2rem 2rem;
}
.rol .create-rol input {
  width: 250px;
}

/* delete */
.delete-item:hover {
  color: red;
  text-decoration: underline;
  cursor: pointer;
}

.rol .configuracion {
  margin-top: 4rem;
}

.rol /deep/ .headerClass {
  color: var(--gray-color);
  font-size: 0.8rem;
}

.modal-config label {
  font-weight: bold;
  margin-top: 1rem;
}
</style>
