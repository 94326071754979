var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rol" },
    [
      _c("h3", [_vm._v(_vm._s(_vm.$t("admin.role.subtitle")))]),
      _c(
        "div",
        { staticClass: "create-rol", attrs: { id: "form-role" } },
        [
          _c("label", [
            _c("b", [
              _vm._v(_vm._s(_vm.$t("admin.role.name")) + " - "),
              _vm.isEditRol
                ? _c("span", [_vm._v(_vm._s(_vm.$t("admin.role.editMode")))])
                : _c("span", [
                    _vm._v(" " + _vm._s(_vm.$t("admin.role.createMode")))
                  ])
            ])
          ]),
          _c(
            "b-row",
            [
              _c("b-form-input", {
                attrs: {
                  placeholder: this.$i18n.t("admin.role.addRole"),
                  state: _vm.validationNameRol
                },
                model: {
                  value: _vm.rolName,
                  callback: function($$v) {
                    _vm.rolName = $$v
                  },
                  expression: "rolName"
                }
              }),
              _vm.validationSaveRol
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn btn-blue-dark ml-3",
                      attrs: {
                        disabled: _vm.isLoadingDone || !_vm.validationNameRol
                      },
                      on: { click: _vm.saveRol }
                    },
                    [
                      _vm.isLoadingDone
                        ? _c(
                            "span",
                            [
                              _c("b-spinner", {
                                staticClass: "align-middle",
                                attrs: { small: "" }
                              }),
                              _vm._v(" Loading...")
                            ],
                            1
                          )
                        : _c("span", [
                            _vm._v(_vm._s(_vm.$t("admin.medicines.done")))
                          ])
                    ]
                  )
                : _vm._e(),
              _vm.isEditRol && !_vm.isLoadingDone
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn btn-blue-dark ml-3",
                      attrs: { disabled: _vm.isLoadingDone },
                      on: { click: _vm.createMode }
                    },
                    [_vm._v(" Modo Crear ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("small", [_vm._v(_vm._s(_vm.$t("admin.role.msgRole")))]),
      _vm.configRol.administrador.pestana.adminRoles.acciones.listarRol
        ? [
            _c("b-table", {
              staticClass: "mt-5",
              attrs: {
                items: _vm.itemsRol,
                fields: _vm.fields,
                "thead-tr-class": "headerClass",
                busy: _vm.isLoadingRolesList
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "cell(text)",
                    fn: function(data) {
                      return [_c("b", [_vm._v(_vm._s(data.value))])]
                    }
                  },
                  {
                    key: "cell(actions)",
                    fn: function(row) {
                      return [
                        _c(
                          "a",
                          { attrs: { href: "#form-role" } },
                          [
                            _vm.configRol.administrador.pestana.adminRoles
                              .acciones.editarRol
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2 bg-success",
                                    attrs: { size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateRol(row.item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.role.edit")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.configRol.administrador.pestana.adminRoles.acciones
                          .eliminarRol
                          ? _c(
                              "b-button",
                              {
                                staticClass: "mr-2 bg-danger",
                                attrs: { size: "sm" },
                                on: {
                                  click: function($event) {
                                    return _vm.handleDeleteRol(
                                      row.item.value._id
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("admin.role.delete")) +
                                    " "
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  },
                  {
                    key: "table-busy",
                    fn: function() {
                      return [
                        _c(
                          "div",
                          { staticClass: "text-center text-primary my-2" },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _c("strong", [_vm._v("Loading...")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                ],
                null,
                false,
                2269728589
              )
            })
          ]
        : [
            _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
              _c("p", [
                _vm._v(" " + _vm._s(_vm.$t("admin.role.msgPermissions")) + " ")
              ])
            ])
          ],
      _c("hr"),
      _c(
        "div",
        { staticClass: "configuracion", attrs: { role: "group" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-4 align-items-center text-align-end" },
            [
              _c("b-col", { attrs: { cols: "5" } }),
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    { attrs: { size: "md" } },
                    [
                      _c("b-form-input", {
                        staticClass: "custom-input",
                        attrs: {
                          id: "filter-input",
                          type: "search",
                          placeholder: this.$i18n.t("admin.role.search")
                        },
                        model: {
                          value: _vm.filterRol,
                          callback: function($$v) {
                            _vm.filterRol = $$v
                          },
                          expression: "filterRol"
                        }
                      })
                    ],
                    1
                  ),
                  _c("b-icon", {
                    staticClass: "input__search__icon",
                    attrs: {
                      icon: "search",
                      "aria-hidden": "true",
                      "flip-h": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _vm.configRol.administrador.pestana.adminRoles.acciones
                    .crearConfigRol
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button btn-blue-dark ml-0",
                          attrs: { variant: "outline-primary" },
                          on: { click: _vm.newConfiguration }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("admin.role.createConf")) + " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.configRol.administrador.pestana.adminRoles.acciones
            .listarConfigRol
            ? [
                _c("b-table", {
                  staticClass: "tableFirst",
                  attrs: {
                    items: _vm.itemsDataConfig,
                    fields: _vm.fieldsRol,
                    filter: _vm.filterRol,
                    "filter-included-fields": _vm.filterOn,
                    stacked: "lg",
                    "show-empty": "",
                    hover: "",
                    "table-variant": "light",
                    "sort-icon-left": "",
                    borderless: "",
                    "thead-tr-class": "table-role",
                    "tbody-tr-class": "table-role",
                    busy: _vm.isLoadingConfigRol
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "cell(rol)",
                        fn: function(row) {
                          return [_c("b", [_vm._v(_vm._s(row.value))])]
                        }
                      },
                      {
                        key: "cell(pais)",
                        fn: function(row) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.value === "US"
                                    ? "USA"
                                    : row.value === "MX"
                                    ? "Mexico"
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(sucursal)",
                        fn: function(row) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  row.value.length
                                    ? row.value.join(", ")
                                    : "N/A"
                                ) +
                                " "
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(actions)",
                        fn: function(row) {
                          return [
                            _vm.configRol.administrador.pestana.adminRoles
                              .acciones.editarConfigRol
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2 bg-success",
                                    attrs: { size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateConfiguration(row.item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.role.edit")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminRoles
                              .acciones.clonarRol
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2",
                                    attrs: { size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.cloneConfiguration(row.item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.role.clone")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminRoles
                              .acciones.eliminarConfigRol
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mr-2 bg-danger",
                                    attrs: { size: "sm" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleDeleteConfig(row.item)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("admin.role.delete")) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "table-busy",
                        fn: function() {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center text-primary my-2" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle"
                                }),
                                _c("strong", [_vm._v("Loading...")])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    false,
                    3017996014
                  )
                })
              ]
            : [
                _c("b-alert", { attrs: { show: "", dismissible: "" } }, [
                  _c("p", [
                    _vm._v(
                      " " + _vm._s(_vm.$t("admin.role.msgPermissionList")) + " "
                    )
                  ])
                ])
              ]
        ],
        2
      ),
      _c("ModalForm", {
        attrs: {
          modalShow: _vm.modalShow,
          validation: _vm.validation,
          configurationRol: _vm.configurationRol,
          isEdit: _vm.isEditConfig,
          itemsRol: _vm.itemsRol,
          getConfigRoles: _vm.getConfigRoles,
          allConfigRol: _vm.allConfigRol
        },
        on: { closeModal: _vm.closeModal }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }