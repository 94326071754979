var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            scrollable: "",
            title: this.$i18n.t("admin.role.formRole"),
            "no-close-on-backdrop": "",
            "hide-header-close": ""
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function() {
                return [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "justify-content-end buttons-dropdown" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "btn btn-blue-dark-outline mr-3",
                              on: {
                                click: function($event) {
                                  return _vm.$emit("closeModal")
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("admin.users.cancel")) + " "
                              )
                            ]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "btn btn-blue-dark ml-0",
                              attrs: { disabled: _vm.isLoadingDone },
                              on: { click: _vm.handleConfig }
                            },
                            [
                              !_vm.isLoadingDone
                                ? _c(
                                    "span",
                                    [
                                      _vm.isEdit
                                        ? _c("span", [_vm._v("Editar")])
                                        : _c("span", [_vm._v("Crear")]),
                                      _c("b-icon", {
                                        staticClass: "icon ml-2",
                                        attrs: {
                                          icon: "arrow-right",
                                          "aria-hidden": "true"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _c(
                                    "span",
                                    [
                                      _c("b-spinner", {
                                        staticClass: "align-middle",
                                        attrs: { small: "" }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("admin.medicines.loading")
                                          ) +
                                          " "
                                      )
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.modalShow,
            callback: function($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow"
          }
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "mt-3 font-weight-bold" }, [
                    _vm._v(" " + _vm._s(_vm.$t("admin.role.role")) + " * ")
                  ]),
                  _c("b-form-select", {
                    staticClass: "select mt-0",
                    attrs: {
                      options: _vm.itemsRol,
                      size: "sm",
                      disabled: _vm.isEdit
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: { name: "" }, disabled: "" } },
                              [
                                _vm._v(
                                  "-- " +
                                    _vm._s(_vm.$t("admin.role.selectRole")) +
                                    " --"
                                )
                              ]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.form.rol,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "rol", $$v)
                      },
                      expression: "form.rol"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          !_vm.form.rol.name.toLowerCase().includes("super admin")
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("label", { staticClass: "mt-3 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("admin.role.country")) + " *")
                      ]),
                      _c("b-form-select", {
                        staticClass: "mt-0 select",
                        attrs: {
                          options: _vm.optionsCountry,
                          size: "sm",
                          disabled: _vm.isEdit
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: "N/A", disabled: "" } },
                                    [
                                      _vm._v(
                                        "-- " +
                                          _vm._s(
                                            _vm.$t("admin.role.selectCountry")
                                          ) +
                                          " --"
                                      )
                                    ]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          504750084
                        ),
                        model: {
                          value: _vm.form.pais,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "pais", $$v)
                          },
                          expression: "form.pais"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.form.rol.name.toLowerCase().includes("super admin")
            ? _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("label", { staticClass: "mt-3 font-weight-bold" }, [
                        _vm._v(_vm._s(_vm.$t("admin.role.branch")) + " *")
                      ]),
                      _c("multi-select", {
                        attrs: {
                          options: _vm.optionsSucursal,
                          "selected-options": _vm.form.sucursal,
                          placeholder: this.$i18n.t("admin.role.selectBranch")
                        },
                        on: { select: _vm.onSelect }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "accordion mt-5", attrs: { role: "tablist" } },
            [
              _vm.form.rol.name.toLowerCase().includes("admin")
                ? _c(
                    "b-card",
                    { staticClass: "mb-1", attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        {
                          staticClass: "p-1",
                          attrs: { "header-tag": "header", role: "tab" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.accordion-admin",
                                  modifiers: { "accordion-admin": true }
                                }
                              ],
                              attrs: { block: "", variant: "info" }
                            },
                            [
                              _c("b", [
                                _vm._v(
                                  _vm._s(_vm.$t("admin.role.administrator"))
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "b-collapse",
                        {
                          attrs: {
                            id: "accordion-admin",
                            accordion: "my-accordion",
                            role: "tabpanel"
                          }
                        },
                        [
                          _vm.formValidation
                            ? _c(
                                "b-card-body",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              class: _vm.formValidation
                                                .administrador.ventana
                                                ? null
                                                : "collapsed",
                                              attrs: {
                                                "aria-expanded": _vm
                                                  .formValidation.administrador
                                                  .ventana
                                                  ? "true"
                                                  : "false",
                                                "aria-controls": "collapse-4",
                                                variant: "light"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.formValidation.administrador.ventana = !_vm
                                                    .formValidation
                                                    .administrador.ventana
                                                }
                                              }
                                            },
                                            [
                                              _c("b-icon", {
                                                attrs: {
                                                  icon: _vm.formValidation
                                                    .administrador.ventana
                                                    ? "check2-square"
                                                    : "square",
                                                  variant: "success",
                                                  "aria-hidden": "true"
                                                }
                                              }),
                                              _c("b", { staticClass: "ml-3" }, [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "admin.role.accessWindow"
                                                    )
                                                  )
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-collapse",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { id: "collapse-4" },
                                              model: {
                                                value:
                                                  _vm.formValidation
                                                    .administrador.ventana,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formValidation
                                                      .administrador,
                                                    "ventana",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formValidation.administrador.ventana"
                                              }
                                            },
                                            [
                                              _c(
                                                "b-card",
                                                [
                                                  _c(
                                                    "b-row",
                                                    _vm._l(
                                                      _vm.adminSection,
                                                      function(item, idx) {
                                                        return _c(
                                                          "b-col",
                                                          {
                                                            key: "admin-" + idx,
                                                            staticClass: "mt-3",
                                                            attrs: { cols: "6" }
                                                          },
                                                          [
                                                            _c(
                                                              "b-button",
                                                              {
                                                                class: _vm
                                                                  .formValidation
                                                                  .administrador
                                                                  .pestana[
                                                                  item.key
                                                                ].permiso
                                                                  ? null
                                                                  : "collapsed",
                                                                attrs: {
                                                                  "aria-expanded": _vm
                                                                    .formValidation
                                                                    .administrador
                                                                    .pestana[
                                                                    item.key
                                                                  ].permiso
                                                                    ? "true"
                                                                    : "false",
                                                                  "aria-controls":
                                                                    "collapse-4",
                                                                  variant:
                                                                    "light"
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.formValidation.administrador.pestana[
                                                                      item.key
                                                                    ].permiso = !_vm
                                                                      .formValidation
                                                                      .administrador
                                                                      .pestana[
                                                                      item.key
                                                                    ].permiso
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: _vm
                                                                      .formValidation
                                                                      .administrador
                                                                      .pestana[
                                                                      item.key
                                                                    ].permiso
                                                                      ? "check2-square"
                                                                      : "square",
                                                                    variant:
                                                                      "success",
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                }),
                                                                _c(
                                                                  "b",
                                                                  {
                                                                    staticClass:
                                                                      "ml-3"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-collapse",
                                                              {
                                                                staticClass:
                                                                  "mt-2",
                                                                attrs: {
                                                                  id:
                                                                    "collapse-4"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formValidation
                                                                      .administrador
                                                                      .pestana[
                                                                      item.key
                                                                    ].permiso,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formValidation
                                                                        .administrador
                                                                        .pestana[
                                                                        item.key
                                                                      ],
                                                                      "permiso",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "formValidation.administrador.pestana[item.key].permiso"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "b-card",
                                                                  _vm._l(
                                                                    item.actions,
                                                                    function(
                                                                      el,
                                                                      idx
                                                                    ) {
                                                                      return _c(
                                                                        "div",
                                                                        {
                                                                          key:
                                                                            "admin-actions" +
                                                                            idx
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "custom-actions",
                                                                              attrs: {
                                                                                variant:
                                                                                  "light"
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.formValidation.administrador.pestana[
                                                                                    item.key
                                                                                  ].acciones[
                                                                                    el.key
                                                                                  ] = !_vm
                                                                                    .formValidation
                                                                                    .administrador
                                                                                    .pestana[
                                                                                    item
                                                                                      .key
                                                                                  ]
                                                                                    .acciones[
                                                                                    el
                                                                                      .key
                                                                                  ]
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "b-icon",
                                                                                {
                                                                                  attrs: {
                                                                                    icon: _vm
                                                                                      .formValidation
                                                                                      .administrador
                                                                                      .pestana[
                                                                                      item
                                                                                        .key
                                                                                    ]
                                                                                      .acciones[
                                                                                      el
                                                                                        .key
                                                                                    ]
                                                                                      ? "check2-square"
                                                                                      : "square",
                                                                                    variant:
                                                                                      "success",
                                                                                    "aria-hidden":
                                                                                      "true"
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _c(
                                                                                "b",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-3"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      el.name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-sspta",
                              modifiers: { "accordion-sspta": true }
                            }
                          ],
                          attrs: { block: "", variant: "info" }
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("admin.role.sspta")) + " ")
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-sspta",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _vm.formValidation
                        ? _c(
                            "b-card-body",
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          class: _vm.formValidation.sspta
                                            .ventana
                                            ? null
                                            : "collapsed",
                                          attrs: {
                                            "aria-expanded": _vm.formValidation
                                              .sspta.ventana
                                              ? "true"
                                              : "false",
                                            "aria-controls": "collapse-1",
                                            variant: "light"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.formValidation.sspta.ventana = !_vm
                                                .formValidation.sspta.ventana
                                            }
                                          }
                                        },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: _vm.formValidation.sspta
                                                .ventana
                                                ? "check2-square"
                                                : "square",
                                              variant: "success",
                                              "aria-hidden": "true"
                                            }
                                          }),
                                          _c("b", { staticClass: "ml-3" }, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "admin.role.accessWindow"
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-collapse",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { id: "collapse-1" },
                                          model: {
                                            value:
                                              _vm.formValidation.sspta.ventana,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.formValidation.sspta,
                                                "ventana",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "formValidation.sspta.ventana"
                                          }
                                        },
                                        [
                                          _vm.configurationRol.id !==
                                          "615f355da4a7246cd5a9ac3d"
                                            ? _c(
                                                "b-card",
                                                [
                                                  _c(
                                                    "b-row",
                                                    _vm._l(
                                                      _vm.optionsSSPTA,
                                                      function(item, idx) {
                                                        return _c(
                                                          "b-col",
                                                          {
                                                            key: "sspta-" + idx,
                                                            staticClass: "mt-3",
                                                            attrs: { cols: "6" }
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "custom-actions",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.$nextTick(
                                                                      function() {
                                                                        _vm.formValidation.sspta.pestana[
                                                                          item.key
                                                                        ] = !_vm
                                                                          .formValidation
                                                                          .sspta
                                                                          .pestana[
                                                                          item
                                                                            .key
                                                                        ]
                                                                      }
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c("b-icon", {
                                                                  attrs: {
                                                                    icon: _vm
                                                                      .formValidation
                                                                      .sspta
                                                                      .pestana[
                                                                      item.key
                                                                    ]
                                                                      ? "check2-square"
                                                                      : "square",
                                                                    variant:
                                                                      "success",
                                                                    "aria-hidden":
                                                                      "true"
                                                                  }
                                                                }),
                                                                _c(
                                                                  "b",
                                                                  {
                                                                    staticClass:
                                                                      "ml-3"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.name
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c("b-col", { attrs: { cols: "4" } }),
                                  _c("b-col", { attrs: { cols: "4" } })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-3",
                              modifiers: { "accordion-3": true }
                            }
                          ],
                          attrs: { block: "", variant: "info" }
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("admin.role.history")))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-3",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _vm.formValidation
                        ? _c(
                            "b-card-body",
                            [
                              _c(
                                "b-button",
                                {
                                  class: _vm.formValidation.history.ventana
                                    ? null
                                    : "collapsed",
                                  attrs: {
                                    "aria-expanded": _vm.formValidation.history
                                      .ventana
                                      ? "true"
                                      : "false",
                                    "aria-controls": "collapse-1",
                                    variant: "light"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.formValidation.history.ventana = !_vm
                                        .formValidation.history.ventana
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.formValidation.history.ventana
                                        ? "check2-square"
                                        : "square",
                                      variant: "success",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _c("b", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.role.accessWindow"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-4",
                              modifiers: { "accordion-4": true }
                            }
                          ],
                          attrs: { block: "", variant: "info" }
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("admin.role.medicines")))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-4",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _vm.formValidation
                        ? _c(
                            "b-card-body",
                            [
                              _c(
                                "b-button",
                                {
                                  class: _vm.formValidation.medicina.ventana
                                    ? null
                                    : "collapsed",
                                  attrs: {
                                    "aria-expanded": _vm.formValidation.medicina
                                      .ventana
                                      ? "true"
                                      : "false",
                                    "aria-controls": "collapse-1",
                                    variant: "light"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.formValidation.medicina.ventana = !_vm
                                        .formValidation.medicina.ventana
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.formValidation.medicina.ventana
                                        ? "check2-square"
                                        : "square",
                                      variant: "success",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _c("b", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.role.accessWindow"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-5",
                              modifiers: { "accordion-5": true }
                            }
                          ],
                          attrs: { block: "", variant: "info" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.$t("admin.role.cycles")))])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-5",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _vm.formValidation
                        ? _c(
                            "b-card-body",
                            [
                              _c(
                                "b-button",
                                {
                                  class: _vm.formValidation.cycles.ventana
                                    ? null
                                    : "collapsed",
                                  attrs: {
                                    "aria-expanded": _vm.formValidation.cycles
                                      .ventana
                                      ? "true"
                                      : "false",
                                    "aria-controls": "collapse-1",
                                    variant: "light"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.formValidation.cycles.ventana = !_vm
                                        .formValidation.cycles.ventana
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.formValidation.cycles.ventana
                                        ? "check2-square"
                                        : "square",
                                      variant: "success",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _c("b", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.role.accessWindow"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-6",
                              modifiers: { "accordion-6": true }
                            }
                          ],
                          attrs: { block: "", variant: "info" }
                        },
                        [_c("b", [_vm._v(_vm._s(_vm.$t("admin.role.recipe")))])]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-6",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _vm.formValidation
                        ? _c(
                            "b-card-body",
                            [
                              _c(
                                "b-button",
                                {
                                  class: _vm.formValidation.recipe.ventana
                                    ? null
                                    : "collapsed",
                                  attrs: {
                                    "aria-expanded": _vm.formValidation.recipe
                                      .ventana
                                      ? "true"
                                      : "false",
                                    "aria-controls": "collapse-1",
                                    variant: "light"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.formValidation.recipe.ventana = !_vm
                                        .formValidation.recipe.ventana
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon: _vm.formValidation.recipe.ventana
                                        ? "check2-square"
                                        : "square",
                                      variant: "success",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _c("b", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.role.accessWindow"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mb-1", attrs: { "no-body": "" } },
                [
                  _c(
                    "b-card-header",
                    {
                      staticClass: "p-1",
                      attrs: { "header-tag": "header", role: "tab" }
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.accordion-7",
                              modifiers: { "accordion-7": true }
                            }
                          ],
                          attrs: { block: "", variant: "info" }
                        },
                        [
                          _c("b", [
                            _vm._v(_vm._s(_vm.$t("formMedical.titulo")))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: "accordion-7",
                        accordion: "my-accordion",
                        role: "tabpanel"
                      }
                    },
                    [
                      _vm.formValidation
                        ? _c(
                            "b-card-body",
                            [
                              _c(
                                "b-button",
                                {
                                  class:
                                    _vm.formValidation.onboardMedico &&
                                    _vm.formValidation.onboardMedico.ventana
                                      ? null
                                      : "collapsed",
                                  attrs: {
                                    "aria-expanded":
                                      _vm.formValidation.onboardMedico &&
                                      _vm.formValidation.onboardMedico.ventana
                                        ? "true"
                                        : "false",
                                    "aria-controls": "collapse-1",
                                    variant: "light"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeStatusOnboarding()
                                    }
                                  }
                                },
                                [
                                  _c("b-icon", {
                                    attrs: {
                                      icon:
                                        _vm.formValidation.onboardMedico &&
                                        _vm.formValidation.onboardMedico.ventana
                                          ? "check2-square"
                                          : "square",
                                      variant: "success",
                                      "aria-hidden": "true"
                                    }
                                  }),
                                  _c("b", { staticClass: "ml-3" }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.role.accessWindow"))
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }